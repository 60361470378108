const navItems = [
  { 
    title: 'BOOK NOW',
    link: 'head',
    icon: ''
  },
  {
    title: 'ABOUT',
    link: 'about',
    icon: ''
  },
  {
    title: 'GALLERY',
    link: 'gallery',
    icon: ''
  },
  {
    title: 'SOCIAL',
    link: 'footer',
    icon: ''
  }
]

export default navItems;